<div class="sheet-container">
  <h5 class="mt-3">{{'choose-country-code' | language}}</h5>
  <div class="search">
    <input class="search-input" placeholder="{{'search-code' | language}}"  [(ngModel)]="search" (input)="searchText()">
  </div>
  <div>
    <mat-selection-list [multiple]="false" (selectionChange)="countrySelected()" [(ngModel)]="selection">
      <mat-list-option *ngFor="let country of results" [value]="country">
        {{country.name}} ({{country.dial_code}})
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>
