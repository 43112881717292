import {Component, Input, OnInit} from '@angular/core';
import {Venue} from "../models/Venue";
import {MatDialog} from "@angular/material/dialog";
import {CountryChooserComponent} from "../country-chooser/country-chooser.component";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {QTermsComponent} from "../qterms/qterms.component";
import {SessionService} from "../services/session.service";
import {ApiService} from "../services/api.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslationService} from "../translation.service";
@Component({
  selector: ' app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  @Input() venue: Venue | undefined;
  seat = '';
  enableWhatsAppCloud: boolean = false;
  seating = {
    first: "first-available",
    standing: false,
    table: false,
    bar: false
  };
  public code = '';
  form = this.fb.group({
    // email: ['', [Validators.required, Validators.email]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    notes: ['', [Validators.required]],
    mobileCountryCode: ['+852', [Validators.required]],
    mobileNumber: ['', [Validators.required]],
  });
  public guestCount = 1;
  loading = false;

  constructor(private dialog: MatDialog, public session: SessionService,
              private bottomSheet: MatBottomSheet, private api: ApiService,
              private fb: FormBuilder, private lang: TranslationService) { }

  ngOnInit(): void {
    this.form.get('mobileCountryCode')?.setValue(`+${this.venue?.phoneCountryCode || '852'}`);
  }

  openCountryCodes() {
    const btf = this.bottomSheet.open(CountryChooserComponent);
    btf.afterDismissed().subscribe(data => {
      data && this.form.get('mobileCountryCode')?.setValue(data);
    })
  }

  confirmQueue(): void {
    const data = {
      title: this.lang.getString('q-policy'),
      content: this.lang.getString('notify-when-ready') + this.lang.getString('confirm-q-content'),
      btn: this.lang.getString('i-agree')
    }
    this.dialog.open(QTermsComponent, {data}).afterClosed().subscribe(data => {
      if (data) {
        // proceed
        this.loading = true;
        const data = this.form.value;
        if (data.mobileCountryCode.startsWith('+')) {
          data.mobileCountryCode = data.mobileCountryCode.slice(1);
        }
        const seatingPreferences: string[] = [];
        if (this.seating.first === 'false') {
          Object.keys(this.seating).forEach(k => {
            // @ts-ignore
            if (this.seating[k] && this.seating[k] !== 'false') {
              seatingPreferences.push(k.toUpperCase())
            }
          })
        }

        const body: any = {
          guestDetails: data,
          waitlistEntryDetails: {
            guestCount: this.guestCount,
            signupChannel: "WEB",
            communicationPreference: "MOBILE_APP",
            seatingPreferences,
            notes: this.form.value.notes || ''
          }
        }

        if(this.enableWhatsAppCloud){
          body.waitlistEntryDetails['enableWhatsAppCloud'] = true
        }

        this.api.completeWaitListEntry(this.session.token, body)
          .subscribe(data => {
            this.loading = true;
            this.session.waitList = data.waitlistEntry;
            this.session.formatMobile();
            // Entry successful
          }, error => {
            console.log('Error', error);
            this.loading = false;
          });
      }
    })
  }

  changeSeat(seat: string) {
    if (seat === 'first-available') {
      this.seating = {
        first: seat,
        table: false,
        standing: false,
        bar: false
      }
    } else {
      this.seating.first = 'false';
      this.seat = seat;
    }
  }

  guestCountChanged(num: number) {
    this.guestCount = num;
  }

  guestCountMaxValue(): number {
    const num = (Number(this.venue?.maxGroupSize) || 0)
    return num || Infinity
  }
}
