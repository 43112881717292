import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {Claim} from "../models/Claim";
import {CommonRes, WaitListEntry, WaitListEntryRes} from "../models/WaitListEntry";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  BASE_URL = environment.API_BASE_URL;

  API = {
    CLAIM_WAITLIST_ENTRY: this.BASE_URL + '/customer/waitlist-entry/claim',
    GET_WAITLIST_ENTRY: this.BASE_URL + '/customer/waitlist-entry/',
    CHANGE_WAITLIST_STATUS: this.BASE_URL + '/customer/waitlist-entry/status/',
    FORGOT_PASSWORD: this.BASE_URL + '/admin/auth/password-reset',
    PUBLIC_CONTACT: this.BASE_URL + '/public/contact',
  }

  constructor(private http: HttpClient) { }

  claimWaitListEntry(code: string): Observable<Claim>{
    return this.http.post<Claim>(this.API.CLAIM_WAITLIST_ENTRY, {uniqueCode: code});
  }

  claimWaitListEntryWithCode(code: string, digit: string): Observable<WaitListEntryRes>{
    return this.http.post<WaitListEntryRes>(this.API.CLAIM_WAITLIST_ENTRY, {uniqueCode: code, last4PhoneDigits: digit});
  }

  getWaitListEntry(token: string): Observable<WaitListEntryRes>{
    const headers = {Authorization: 'Bearer ' + token}
    return this.http.get<WaitListEntryRes>(this.API.GET_WAITLIST_ENTRY, {headers});
  }

  completeWaitListEntry(token: string, body: any): Observable<WaitListEntryRes>{
    const headers = {Authorization: 'Bearer ' + token}
    return this.http.put<WaitListEntryRes>(this.API.GET_WAITLIST_ENTRY, body, {headers});
  }

  waitListStatusUpdate(token: string, body: any): Observable<WaitListEntryRes>{
    const headers = {Authorization: 'Bearer ' + token}
    return this.http.put<WaitListEntryRes>(this.API.CHANGE_WAITLIST_STATUS, body, {headers})
  }

  forgotPassword(token: string, password: string): Observable<WaitListEntryRes>{
    return this.http.post<any>(this.API.FORGOT_PASSWORD, {token, password});
  }

  publicContact(body: {name: string; email: string; message: string}): Observable<CommonRes>{
    return this.http.post<CommonRes>(this.API.PUBLIC_CONTACT, body);
  }
}
