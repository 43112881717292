import {Component, Input, OnInit} from '@angular/core';
import {SessionService} from "../services/session.service";
import {WaitListEntry} from "../models/WaitListEntry";
import {QTermsComponent} from "../qterms/qterms.component";
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "../services/api.service";
import {environment} from "../../environments/environment";
import {TranslationService} from "../translation.service";
import {f_str, formatDateEnd} from "../utils";
@Component({
  selector: 'app-qposition',
  templateUrl: './qposition.component.html',
  styleUrls: ['./qposition.component.scss']
})
export class QPositionComponent implements OnInit {

  @Input() waitList: WaitListEntry | undefined;
  showQr = false;
  url = environment.WEB_URL + '/q/' + this.session.code;

  constructor(public session: SessionService, private dialog: MatDialog,
              private api: ApiService, private lang: TranslationService) {
  }

  ngOnInit(): void {

  }

  cancelTable(b: boolean = false) {
    const data = {
      title: this.lang.getString('exit-q'),
      content: b ? this.lang.getString((this.waitList?.guestCount || 0) > 1 ? 'cancel-seats' : 'cancel-seat') : this.lang.getString('cancel-table-text'),
      btn: b ? this.lang.getString('confirm') : this.lang.getString('exit')
    }
    this.dialog.open(QTermsComponent, {data}).afterClosed().subscribe(data => {
      if (data) {
        const body = {
          status: 'CANCELED',
        }
        this.api.waitListStatusUpdate(this.session.token, body).subscribe(w => {
          if (w.status === 'success') {
            this.session.waitList = w.waitlistEntry;
            this.session.formatMobile();
          }
        });
      }
    });
  }

  confirmTable(min: number) {
    const body = {
      status: 'CONFIRMED',
      arrivalInMinutes: min
    }
    this.api.waitListStatusUpdate(this.session.token, body).subscribe(w => {
      if (w.status === 'success') {
        this.session.waitList = w.waitlistEntry;
        this.session.formatMobile();
      }
    });
  }

  moreTime() {
    const data = {
      title: this.lang.getString('need-more-time'),
      content: this.lang.getString('defer-msg'),
      btn: this.lang.getString('defer-my-table')
    }
    this.dialog.open(QTermsComponent, {data}).afterClosed().subscribe(data => {
      if (data) {
        const body = {
          status: 'DEFERRED',
        }
        this.api.waitListStatusUpdate(this.session.token, body).subscribe(w => {
          if (w.status === 'success') {
            this.session.waitList = w.waitlistEntry;
            this.session.formatMobile();
          }
        });
      }
    });
  }

  enableWaitlistEntryExpiration() {
    return this.waitList?.venue?.enableWaitlistEntryExpiration === true
  }

  openQrCode() {
    this.showQr = !this.showQr;
  }

  showExpiredTips(): string {
    return this.waitList?.expirationDate && this.enableWaitlistEntryExpiration() ? f_str(this.lang.getString(`expired-time-unit`), [formatDateEnd(this.lang.lang || 'en', this.waitList?.expirationDate) || '']) : this.lang.getString( 'expired-tips')
  }

  showNotifiedTips(): string {
    return this.waitList?.expirationDate && this.enableWaitlistEntryExpiration() ? f_str(this.lang.getString(`confirm-arrival-time-unit`), [formatDateEnd(this.lang.lang || 'en', this.waitList?.expirationDate) || '']) : this.lang.getString( 'confirm-arrival-time')
  }

  showArrivalTimeTips(): string {
    return this.waitList?.expirationDate && this.enableWaitlistEntryExpiration() ? f_str(this.lang.getString(`arrival-within-unit`), [formatDateEnd(this.lang.lang || 'en', this.waitList?.expirationDate) || '']) : this.lang.getString( 'arrival-within')
  }

}
