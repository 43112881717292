import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {WaitListEntry} from "../models/WaitListEntry";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public code = '';
  token = '';
  waitList: WaitListEntry | undefined;
  waitListId = '';
  lang = 'English';
  constructor(private api: ApiService, private router: Router) {}

  init(code: string){
    this.code = code;
    this.token = localStorage.getItem('code-' + this.code) || '';
    this.waitListId = localStorage.getItem('code-' + this.code + '-wait') || '';
    if (this.token.length > 0){
      this.api.getWaitListEntry(this.token).subscribe(data => {
        this.waitList = data.waitlistEntry;
        console.log('Data', data);
        this.formatMobile();
      }, error => {
        console.log('Error', error);
      });
    } else {
      // token is not in memory request a new one
      this.api.claimWaitListEntry(this.code).subscribe(data => {
        if (data.status === 'success'){
          this.setData(data);
        }
      }, error => {
        console.log('Error while claiming!', error.status, error);
        if (error.status === 400){
          this.router.navigateByUrl(`/code/${this.code}`)
        }
      });
    }
  }

  formatMobile(): void{
    const mobile = this.waitList?.guest.mobileNumber.replace(/.(?=.{4})/g, '*');
    if (this.waitList?.guest){
      this.waitList.guest.mobileNumber = mobile || '';
    }
  }

  public setData(data: any) {
    this.token = data.token;
    localStorage.setItem('code-' + this.code, this.token);
    this.waitListId = data.waitlistEntry._id;
    this.waitList = data.waitlistEntry;
    localStorage.setItem('code-' + this.code + '-wait', data.waitlistEntry._id);
  }
}
