import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {SignupComponent} from "./signup/signup.component";
import {QueueComponent} from "./queue/queue.component";
import {CodeComponent} from "./code/code.component";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'q/:code',
    component: QueueComponent
  },
  {
    path: 'code/:code',
    component: CodeComponent
  },
  {
    path: 'admin/forgot-password',
    component: ForgotPasswordComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
