import {Component, OnInit} from '@angular/core';
import {CountryCode, CountryCodes} from "../country-codes";
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";

@Component({
  selector: 'app-country-chooser',
  templateUrl: './country-chooser.component.html',
  styleUrls: ['./country-chooser.component.scss']
})
export class CountryChooserComponent implements OnInit {
  countries: Array<CountryCode> = CountryCodes;
  selection: CountryCode[] = [{
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'HK'
  }];
  search = '';
  results: CountryCode[] = [];

  constructor(private _bottomSheetRef: MatBottomSheetRef<CountryChooserComponent>) { }

  ngOnInit(): void {
    this.results = this.countries.map(item => item)
  }

  countrySelected() {
    this._bottomSheetRef.dismiss(this.selection[0].dial_code);
  }

  searchText() {
    if (this.search.length > 0){
      this.results = [];
      const search = this.search.toLowerCase();
      for (const item of this.countries){
        if (item.dial_code.includes(search) || item.name.toLowerCase().includes(search)){
          this.results.push(item);
        }
      }
    } else {
      this.results = this.countries.map(item => item);
    }
  }
}
