import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "../services/api.service";
import {TranslationService} from "../translation.service";
import {QTermsComponent} from "../qterms/qterms.component";
import {SUCCESS} from "../models/WaitListEntry";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private dialog: MatDialog,
              private api: ApiService, private lang: TranslationService) {
  }

  ngOnInit(): void {
  }

  name = '';
  email = '';
  message = '';
  loading: boolean = false;

  showAlert(content: string, cb: Function | undefined = undefined, msg: string | undefined = undefined) {
    const data = {
      content: msg || this.lang.getString(content),
      btn: this.lang.getString('confirm')
    }
    this.dialog.open(QTermsComponent, {data}).afterClosed().subscribe(result => {
      result && cb && cb();
    });
  }

  cleanField() {
    this.name = '';
    this.email = '';
    this.message = '';
  }

  validateEmail() {
    const regex = /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    return regex.test(this.email.trim());
  }

  checkInputField(): boolean {
    if (!this.name.trim()) {
      this.showAlert('input-name')
      return false;
    }
    if (!this.email.trim()) {
      this.showAlert('input-email')
      return false;
    }
    if(!this.validateEmail()){
      this.showAlert('correct-email')
      return false;
    }
    if (!this.message.trim()) {
      this.showAlert('input-message')
      return false;
    }
    return true;
  }

  submit() {
    if (!this.checkInputField() || this.loading) return
    const {name, email, message} = this
    this.loading = true
    this.api.publicContact({name, email, message}).subscribe(res => {
      this.loading = false
      this.cleanField()
      if (res.status == SUCCESS) {
        this.showAlert('reply-message', () => {
          console.log(this.name, this.email, this.message)
        })
      } else if (res.message) {
        this.showAlert('', undefined, res.message)
      }
    }, error => {
      this.loading = false
      console.log(error);
    });

  }
}
