import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-number-selector',
  templateUrl: './number-selector.component.html',
  styleUrls: ['./number-selector.component.scss']
})
export class NumberSelectorComponent implements OnInit {

  @Input() interval = 1;
  @Input() current = 0;
  @Input() max = 100;
  @Input() min = 15;
  @Output() changed = new EventEmitter<number>()

  constructor() {
  }

  ngOnInit(): void {
  }

  increase() {
    if ((this.current + this.interval) <= this.max) {
      this.current = this.current + this.interval;
      this.changed.emit(this.current);
    }
  }

  decrease() {
    if ((this.current - this.interval) >= this.min) {
      this.current = this.current - this.interval;
      this.changed.emit(this.current);
    }
  }
}
