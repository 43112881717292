import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SignupComponent } from './signup/signup.component';
import { QueueComponent } from './queue/queue.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatInputModule} from "@angular/material/input";
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {NumberSelectorComponent} from "./shared/number-selector/number-selector.component";
import {MatRippleModule} from "@angular/material/core";
import { CountryChooserComponent } from './country-chooser/country-chooser.component';
import {MatListModule} from "@angular/material/list";
import {MatDialogModule} from "@angular/material/dialog";
import {MatBottomSheetModule, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {QTermsComponent} from "./qterms/qterms.component";
import { QPositionComponent } from './qposition/qposition.component';
import {HttpClientModule} from "@angular/common/http";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { CodeComponent } from './code/code.component';
import {NgOtpInputModule} from "ng-otp-input";
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import {QRCodeModule} from "angularx-qrcode";
import { LanguageModalComponent } from './language-modal/language-modal.component';
import { LanguagePipe } from './language.pipe';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SignupComponent,
    QueueComponent,
    NumberSelectorComponent,
    CountryChooserComponent,
    QTermsComponent,
    QPositionComponent,
    CodeComponent,
    PrivacyPolicyComponent,
    LanguageModalComponent,
    LanguagePipe,
    ForgotPasswordComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    MatRippleModule,
    MatListModule,
    MatDialogModule,
    MatBottomSheetModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    NgOtpInputModule,
    QRCodeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
