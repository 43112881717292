import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {LanguageModalComponent} from "./language-modal/language-modal.component";

@Injectable({
  providedIn: 'root'
})

/**
 * This service is used to load the language in the app,
 * it fetches the language file and make it available
 * depending on the chosen language
 */
export class TranslationService {

  public languages: {name: string, value: string}[] = [{name: 'English', value: 'en'}, {name: '繁體中文', value: 'zh-Hant'},
    {name: '简体中文', value: 'zh-Hans'}, {name: '日本語', value: 'ja'},
    {name: '한국어', value: 'ko'},
    // {name: 'ภาษาไทย', value: 'th'},
  ];
  public ready = false;
  public lang: string | null = 'en';
  public langDisplay = 'English';
  private translations: any;

  constructor(private http: HttpClient, private bottomSheet: MatBottomSheet) {
    this.init();
  }

  /**
   * This function can be called to find any word in current language
   * @param key is the key of the word
   */
  public getString(key: string) {
    return this.translations ? this.translations[key] : '';
  }

  /**
   * This function is supposed to be called when language is changed
   * @param code is the language code e;g en (English),es(Spanish),ar(Arabic)
   */
  public changeLanguage(code: string) {
    this.ready = false;
    this.lang = code;
    this.langDisplay = (_.find(this.languages, {value: this.lang}))?.name || 'English';
    localStorage.setItem('lang', code);
    this.loadLang();
  }

  private async init() {
    this.lang = localStorage.getItem('lang');
    if (!this.lang) {
      localStorage.setItem('lang', 'en');
      this.lang = 'en';
      this.langDisplay = 'English';
    }
    this.langDisplay = (_.find(this.languages, {value: this.lang}))?.name || 'English';
    this.loadLang();
  }

  public getLang(){
    return this.lang;
  }

  /**
   * This function loads the language file and set the contents
   * to local private object, it uses lang variable to identify
   * chosen language
   */
  private loadLang() {
    this.http.get<any>(`../assets/json/languages/${this.lang}.json`).subscribe(data => {
      this.translations = data;
      this.ready = true;
    }, error => {
      console.log('Unable to load translations', error);
      this.ready = true;
    });
  }

  /**
   * Open dialog to choose a language from available languages
   */
  openLanguage() {
    this.bottomSheet.open(LanguageModalComponent);
  }
}
