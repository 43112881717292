import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../services/session.service";
import {Meta} from "@angular/platform-browser";
import {environment} from "../../environments/environment";
import {TranslationService} from "../translation.service";

@Component({
  selector: 'app-queue',
  templateUrl: './queue.component.html',
  styleUrls: ['./queue.component.scss']
})
export class QueueComponent implements OnInit {

  // waitList: WaitListEntry | undefined;

  constructor(private route: ActivatedRoute, private router: Router, public session: SessionService,
              private meta: Meta, public lang: TranslationService) { }

  ngOnInit(): void {
    this.route.params.subscribe(s => {
      this.session.init(s['code']);
      if (environment.production){
        const url = environment.WEB_URL + this.router.url;
        this.meta.addTag({name: 'apple-itunes-app', content: `app-id=${environment.appId}, app-argument=${url}`})
      }
    });
  }

}
