<div class="sheet-container">
  <div class="row">
    <div class="col">
<!--      <h5 class="mt-3">{{'choose-lang' | language}}</h5>-->
    </div>
    <div class="col-auto">
      <span class="mdi mdi-close fs-36" (click)="close()"></span>
    </div>
  </div>
  <div>
    <mat-selection-list [multiple]="false" (selectionChange)="languageSelected()" [(ngModel)]="selection">
      <mat-list-option *ngFor="let language of lang.languages" [value]="language.value">
        {{language.name}}
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>
