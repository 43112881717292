export function f_str(str: string, args: string[]): string {
  if (!args || !args.length) return str;
  let tmp = str;
  for (let i = 0; i < args.length; i++) {
    tmp = tmp.replace(new RegExp('\\{' + i + '\\}', 'g'), args[i]);
  }
  return tmp;
}

export const formatDate = (local: string, str: string) => {
  const date = new Date(str);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const h = hours > 12 ? hours - 12 : hours;
  const cn = hours > 12 ? '下午' : '上午';
  const en = hours > 12 ? 'PM' : 'AM';
  const minutes = (date.getMinutes()+'').padStart(2, '0');
  return local == 'zh-Hans' || local == 'zh-Hant' ? `${year}年${month}月${day}日 ${cn}${h}:${minutes}` : `${day}/${month}/${year}, ${h}:${minutes} ${en}`;
};

export const formatDateEnd = (local: string, str: string) => {
  const dateStr = formatDate(local, str);
  const endStr = dateStr.split(local == 'zh-Hans' || local == 'zh-Hant' ? ' ' : ', ')[1];
  if (local == 'ko') {
    const arr = endStr.split(' ')
    return `${{AM: '아침', PM: '오후'}[arr[1]]} ${arr[0]}`
  } else if (local == 'ja') {
    const arr = endStr.split(' ')
    return `${f_str({AM: '午前{0}時{1}分', PM: '午後{0}時{1}分'}[arr[1]] || '', arr[0].split(':'))}`
  }
  return endStr
};
