import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../services/api.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  token = '';
  password = '';
  confirmPassword = '';
  private inProgress = false;
  public msg = '';
  public color = '#4caf50';

  constructor(private route: ActivatedRoute, private api: ApiService) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          console.log(params);
          this.token = params['token'];
        }
      );
  }

  resetPassword(){
    // call API here.
    this.inProgress = true;
    this.api.forgotPassword(this.token, this.password).subscribe(data => {
      this.inProgress = false;
      console.log('Data', data);
      this.password = '';
      this.confirmPassword = '';
      this.msg = 'Your password has been updated.'
      this.color = '#4CAF50';
    }, error => {
      console.log('An error occurred!', error);
      this.msg = 'An error occurred!';
      this.color = '#D60B0B'
    });
  }

  btnActive() {
    return !((this.password.length > 0 && this.password === this.confirmPassword) && !this.inProgress);
  }
}
