<div class="page-bg">
  <h3> Nota Q Privacy Policy </h3>
  <p>Nota Dining (&quot;Nota&quot;) Privacy Policy Statement</p>
  <p><br></p>
  <p>General</p>
  <p>When we collect information from you, it is our policy to meet fully the requirements of the Personal Data (Privacy) Ordinance (Chapter 486) of the laws of Hong Kong which regulates the treatment of personal data. Personal data is any information relating to any living individual from which it is practicable to identify that individual. In dealing with personal data, we will ensure compliance by our staff with the standards of security and confidentiality prescribed by the laws of Hong Kong.</p>
  <p><br></p>
  <p>This Statement explains our practice in relation to the collection, retention, storage and sharing of personal data.</p>
  <p><br></p>
  <p>Collection of Personal Data</p>
  <p><br></p>
  <p>From time to time, we will collect personal data, such as (but not limited to) your name, age, title, position, address, contact number (including mobile phone and fax numbers) and email address, from you in connection with our provision of services, activities and facilities.</p>
  <p><br></p>
  <p>We may also generate and compile information about you. Personal data and other information provided by you or generated and compiled by us about you from time to time is collectively referred to as &quot;Your Information&quot;.</p>
  <p><br></p>
  <p>Your Information will be processed for the specific purpose which will be given at the time we collect Your Information. Such purposes may include but is not limited to:</p>
  <p>(i) providing and processing applications or requests for services, activities and facilities, including but not limited to the operation of our club and matters relating to the administration, management, operation and maintenance of the same;</p>
  <p>(ii) communicating with you, including but not limited to responding to your requests for information, services, activities and facilities, and your queries submitted through but not limited to any electronic communication devices;</p>
  <p>(iii) designing and offering contests, games, lucky draws, promotions, surveys and/or events;</p>
  <p>(iv) enabling you to send e-mails, e-cards of preferred pages from our website to friends;</p>
  <p>(v) marketing products, services, activities, facilities and other subjects, pursuant to any consent provided by you (please see further details in &quot;Use of Your Information in Direct Marketing&quot; section in our relevant Personal Information Collection Statement provided to you, and you may opt out from receiving marketing information);</p>
  <p>(vi) tailoring your experience at our website, showing content of interest, displaying the content according to your preferences by use of demographic information which is shared with advertisers on an aggregate basis (without revealing the identity of individual user of our website);</p>
  <p>(vii) diagnosing problems with our server and administering our website by use of your Internet Protocol address;</p>
  <p>(viii) conducting internal statistical research and analysis;</p>
  <p>(ix) making disclosure when required by any law, court order, direction, code or guideline applicable in or outside Hong Kong;</p>
  <p>(x) investigating and handling complaints or cases involving Nota any of our members and affiliates, including Sky Capital Technology Limited and its restaurants.</p>
  <p>(xi) preventing, detecting or investigating suspicious or illegal activities.</p>
  <p><br></p>
  <p>The provision of personal data or any information is voluntary. However, any failure to supply the necessary personal data or information requested by us may result in us being unable to process your requests or provide the services, activities and facilities requested by you.</p>
  <p><br></p>
  <p>In relation to use of Your Information in direct marketing, we will only do so subject to your consent and you have the right to opt-out from such use. You may also change your preference with regard to use of Your Information in direct marketing at any time by sending an e-mail to <a data-fr-linked="true" href="mailto:privacy@nota-q.com">privacy@nota-q.com</a>, writing to us at the address below, or contacting us via the contact information provided in our direct marketing materials.</p>
  <p><br></p>
  <p>We shall make a record of your visit to our website. The record will show the domain name server address part of your email address and details of the pages visited. We only collect aggregate information about the number and types of visitors by reference to their domain name server address. This information is used by us for preparing general statistics on the usage of our website and may also be disclosed by us in the case of any unlawful use of our website.</p>
  <p><br></p>
  <p>We currently use &quot;cookies&quot; on our website which are provided by a third party company on our behalf. The cookies tag each visitor&apos;s browser with a random, unique number. The cookie simply assigns a number and does not collect or disclose any personal data about the person using the browser. By noting where these uniquely numbered browsers on the website we are able, with the assistance of the third party company that helps us to analyse this information, to study traffic patterns and improve our website.</p>
  <p><br></p>
  <p>Accuracy of Personal Data</p>
  <p>Where practicable, we will verify personal data provided to us using generally available methods.</p>
  <p><br></p>
  <p>Security</p>
  <p>We will use such secured encryption systems as are appropriate for the collection of Your Information over the internet, based on the type of information being collected. Unfortunately no security measures are 100% secure and it is possible that others may intercept the information you are sending.</p>
  <p><br></p>
  <p>We will also take such steps as are appropriate to ensure the security of Your Information while it is held by us. This will include password protection and other electronic security methods as well as physical security methods, where appropriate, to help ensure that Your Information can only be accessed by our authorised employees, which employees shall be made aware of the terms of this Statement and our duties to you under this Statement.</p>
  <p><br></p>
  <p>Your Information will not be disclosed to any third party except as provided herein, as notified to you at the time of collection by way of our Personal Information Collection Statement, or with your prior consent. We do not rent, trade or sell Your Information for commercial purposes.</p>
  <p><br></p>
  <p>Hyperlinks</p>
  <p>This Statement only applies to websites of Nota and any of its affiliates. If you click on a hyperlink taking you from our website to another website, we shall not be responsible for the privacy and security practices or the content of such website and you should refer to the privacy policy of each website you enter.</p>
  <p><br></p>
  <p>Disclosure of Personal Data</p>
  <p><br></p>
  <p>We value all personal data received and do our best to maintain its privacy. &nbsp;For our policy please refer to our Personal Information Collection Statement. &nbsp;We may disclose the Data when required by law or court order of any jurisdiction, or as requested by any government or law enforcement authorities or administrative organs. We may disclose the Data as is necessary to bring a legal action or defend any legal action in relation to you.</p>
  <p><br></p>
  <p>For all other personal data collected by us, except with your prior consent or as required by law, we will not transfer or disclose Your Information to any third party except that we may transfer or disclose Your Information in or outside Hong Kong as stated below (and we will not transfer Your Information to another party for it to use in direct marketing):</p>
  <p><br></p>
  <p>1. our subsidiaries, associated companies and/or business associations;</p>
  <p>2. any personnel, agent, adviser, auditor, contractor or service provider who provides services or advice to us in connection with our operations as required to enable us to provide, or assist us in providing, the services, activities or facilities you have requested;</p>
  <p>3. if you consent or do not object to our use of Your Information in direct &nbsp; &nbsp; marketing (please see further details in &quot;Use of Your Information in Direct Marketing&quot; section in our Personal Information Collection Statement), our marketing communications and operations partners with whom we share information for the purpose of providing you with special offers and information;</p>
  <p>4. any person under a duty of confidentiality to us; and</p>
  <p>5. any person to whom we are required to make disclosure under any law, court order, direction, code or guideline applicable in or outside Hong Kong.</p>
  <p>6. You should note that as we have no control over the acts of any third party that is not our agent or service provider (e.g. any governmental agency to whom we are required to disclose Your Information), the privacy protection stated in this Statement ceases to apply to any information which has been disclosed to such a third party in accordance with this Statement. We endeavour to deal only with responsible third parties but assume no responsibility for the privacy protection provided by such third parties who are not our agent or service provider.</p>
  <p><br></p>
  <p>Data Retention</p>
  <p>We will take all reasonably practicable steps not to retain Your Information where it is no longer necessary for fulfilment of the purposes (including directly related purposes) referred to in this Statement or as otherwise specified at the time of collection, unless any retention is required or permitted by law.</p>
  <p><br></p>
  <p>Personal Data Access and Correction</p>
  <p>You are entitled to access personal data we hold about you and, if applicable, to correct or update it. You may do so by</p>
  <p>sending an e-mail to our Guest Relations Manager at <a data-fr-linked="true" href="mailto:privacy@nota-q.com">privacy@nota-q.com</a> or contacting us below:</p>
  <p><br></p>
  <p>Attention: Administrative Team</p>
  <p>Unit 314, Block A, Po Lung Centre</p>
  <p>11 Wang Chiu Road</p>
  <p>Kowloon Bay</p>
  <p>Hong Kong</p>
  <p><br></p>
  <p>Changes to Our Privacy Policy</p>
  <p>We reserve the right to change our privacy policy. However, we shall not do so without posting those changes on our website or any of our communications. If at any point we decide to use personal data in a manner different from that stated at the time it was collected, we will notify you via email or by posting a prominent notice on our website and in the Mobile Application thirty days prior to implementing the change. Your express consent will be needed before we use your personal data in this different manner.</p>

</div>
