import {Component, Input, OnInit} from '@angular/core';
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {TranslationService} from "../translation.service";

@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.scss']
})
export class LanguageModalComponent implements OnInit {

  selection = ['en'];

  constructor(private _bottomSheetRef: MatBottomSheetRef<LanguageModalComponent>, public lang: TranslationService) { }

  ngOnInit(): void {
    this.selection = [(this.lang.lang || 'en')];
  }

  close(){
    this._bottomSheetRef.dismiss();
  }

  languageSelected() {
    this.lang.changeLanguage(this.selection[0]);
    this._bottomSheetRef.dismiss();
  }
}
