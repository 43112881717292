import { Component, OnInit } from '@angular/core';
import {SessionService} from "../services/session.service";
import {ApiService} from "../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslationService} from "../translation.service";

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss']
})
export class CodeComponent implements OnInit {

  code = '';
  digit = '';
  public error = false;
  public errorMsg = '';

  constructor(private api: ApiService, private route: ActivatedRoute,
              private session: SessionService, private router: Router,
              private lang: TranslationService) { }

  ngOnInit(): void {
    this.route.params.subscribe(s => {
      this.code = s['code'];
    });
  }

  onOtpChange($event: string) {
    this.digit = $event;
  }

  submit() {
    this.api.claimWaitListEntryWithCode(this.code, this.digit).subscribe(data => {
      // if all good redirect user
      this.session.setData(data);
      this.session.formatMobile();
      this.router.navigateByUrl(`/q/${this.code}`);
    }, error => {
      console.log(error);
      if (error.status === 400){
        this.error = true;
        this.errorMsg = this.lang.getString('err-4-digits');
      }
    });
  }
}
