<div class="container t-center">
  <div class="forgot-container">
    <div class="logo-container">
      <img src="assets/img/logo-notaq.png" class="logo">
    </div>
    <mat-card class="forgot-card">
      <div class="logo-container">
        <h3 class="white">Forgot Password</h3>
      </div>
      <div class="mt-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Password</mat-label>
          <input placeholder="Password" matInput type="password" [(ngModel)]="password">
          <mat-hint *ngIf="password.length > 0 && password.length < 7">Minimum 7 characters required</mat-hint>
        </mat-form-field>
      </div>

      <div class="mt-2">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Confirmation Password</mat-label>
          <input placeholder="Confirmation Password" matInput type="password" [(ngModel)]="confirmPassword">
          <mat-hint *ngIf="confirmPassword.length > 0 && password !== confirmPassword">Minimum 7 characters required</mat-hint>
        </mat-form-field>
      </div>
    </mat-card>

    <p  class="mt-3 mb-3" [style.color]="color" *ngIf="msg.length > 0">{{msg}}</p>


    <button mat-raised-button class="primary-btn mt-3" [disabled]="btnActive()" (click)="resetPassword()">Submit</button>
  </div>
</div>
