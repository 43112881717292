<div class="content">
  <h6 class="subheading">{{'signup-heading' | language}} {{venue?.name}}</h6>
  <div class="form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <div class="input-container">
            <div class="label">{{'fName' | language}}</div>
            <input class="q-input" formControlName="firstName">
          </div>
        </div>
        <div class="col">
          <div class="input-container">
            <div class="label">{{'lName' | language}}</div>
            <input class="q-input" formControlName="lastName">
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div class="label">{{'mobile' | language}}</div>
        <div class="row align-items-center">
          <div (click)="openCountryCodes()" class="col-3">
            <input class="q-input text-center" formControlName="mobileCountryCode" readonly>
          </div>
          <div class="col-9">
            <div class="input-container">
              <input class="q-input" formControlName="mobileNumber" placeholder="Mobile Number">
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="row mt-2">-->
      <!--        <div class="col">-->
      <!--          <div class="input-container">-->
      <!--            <div class="label">Email</div>-->
      <!--            <input class="q-input" type="email" formControlName="email">-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="row mt-2">
        <div class="col-auto">
          <div class="label">{{'party-size' | language}}</div>
          <app-number-selector (changed)="guestCountChanged($event)" [current]="1" [max]="(guestCountMaxValue())"
                               [min]="1"></app-number-selector>
        </div>
        <div class="col">
          <div class="input-container">
            <div class="label">{{'notes' | language}}</div>
            <input class="q-input" formControlName="notes" type="text">
          </div>
        </div>
      </div>
    </form>

    <div class="mt-3" *ngIf="(venue?.seatingPreferences?.length || 0 > 0)">
      <p>{{'seating-pref' | language}}</p>
      <mat-radio-group [(ngModel)]="seating.first" *ngIf="(venue?.seatingPreferences?.length || 0 > 0)">
        <mat-radio-button (change)="changeSeat('first-available')" value="first-available">
          &nbsp;{{'first-avl' | language}}</mat-radio-button>
      </mat-radio-group>

      <div>
        <mat-checkbox (click)="changeSeat('bar')" [(ngModel)]="seating.bar" *ngIf="venue?.seatingPreferences?.includes('BAR')">
          &nbsp; {{'bar-seat' | language}}</mat-checkbox>
      </div>

      <div>
        <mat-checkbox (click)="changeSeat('table')" [(ngModel)]="seating.table" *ngIf="venue?.seatingPreferences?.includes('TABLE')">
          &nbsp; {{'table-seat' | language}}</mat-checkbox>
      </div>
      <div>
        <mat-checkbox (click)="changeSeat('standing')" [(ngModel)]="seating.standing" *ngIf="venue?.seatingPreferences?.includes('STANDING')">
          &nbsp; {{'standing' | language}}</mat-checkbox>
      </div>

    </div>

    <div class="mt-3" *ngIf="((venue?.communicationChannels?.length || 0) > 0 && venue?.communicationChannels?.includes('WHATSAPP_CLOUD'))">
      <p>{{'comm-pref' | language}}</p>

      <div>
        <mat-checkbox [(ngModel)]="enableWhatsAppCloud" class="signup">
          &nbsp; {{'WhatsApp-Cloud' | language}}</mat-checkbox>
      </div>

    </div>

    <p class="mt-3">{{'agree-q-terms' | language}}</p>

    <button (click)="confirmQueue()" *ngIf="!loading" class="primary-btn fs-20"
            mat-raised-button>{{'join-q'| language}}</button>
    <mat-progress-spinner *ngIf="loading" [diameter]="40" mode="indeterminate"
                          style="margin: auto;"></mat-progress-spinner>

  </div>
</div>
