<div class="w-full bg-nota-bg home-min-w min-h-full box-border space-grotesk">
  <section
    class="w-full pt-8 bg-nota-red box-border text-white md:pl-12 md:pr-12 max-md:pl-6 max-md:pr-6 xl:pl-32 xl:pr-32">
    <div class="max-w-screen-2xl mx-auto">
      <nav class="flex items-center justify-between">
        <div class="flex items-center">
          <a href="/"><img src="assets/home/logo/logo-nota.png" alt="" class="cursor-pointer logo-nota"></a>
          <a href="#allApps" class="text-base ml-10 text-white font-normal hidden xl:text-lg">All apps</a>
        </div>
        <a href="#contactus"><img src="assets/home/contactus.png" class="w-48 cursor-pointer" alt=""></a>
      </nav>
      <div class="flex flex-wrap md:flex-col w-full xl:items-center xl:justify-between xl:flex-row relative">
        <div class="mt-14 xl:w-fit xl:pb-48">
          <div class="text-7xl font-bold max-md:text-4xl lg:text-8xl">
            Smart Queueing
          </div>
          <div class="text-5xl font-light mt-1 max-md:text-3xl lg:text-5xl">
            for Restaurants & Bars
          </div>
          <div class="w-4/6 border-b-2 mt-2 border-nota-yellow lg:w-2/6 xl:w-3/6"></div>
        </div>
        <div class="mt-12 xl:pb-36">
          <img src="assets/home/home-hero-md.png"
               class="relative block md:-left-28 md:w-4/5 lg:w-4/6 max-md:w-full xl:hidden" alt=""/>
          <img src="assets/home/home-hero-3.png" class="relative hidden home-hero xl:block" alt=""/>
        </div>
        <img src="assets/home/bg-xl-shadow.png" alt=""
             class="absolute -bottom-2 bg-xl-shadow right-0  hidden xl:block"/>
      </div>
    </div>
  </section>
  <section class="flex flex-col items-center box-border justify-center py-10 px-4">
    <div class="text-2xl font-normal text-center">We work with top restaurants and bars across Asia</div>
    <div class="xl:flex xl:flex-row">
      <div class="flex flex-col items-center">
        <div class="text-xl font-normal px-10 mt-10 mb-6 text-center md:text-2xl hidden max-md:block">Hong Kong & Shanghai</div>
        <div
          class="flex flex-row flex-wrap items-center pb-4 w-full justify-center border-b border-nota-red md:justify-between max-md:pb-0 max-md:border-t max-md:border-b-0">
          <div class="bg-coa w-full flex mt-10 justify-center max-md:mt-6 md:w-4/12 max-md:h-40 md:h-44">
            <img src="assets/home/logo/logo-coa.png" alt=""/>
          </div>
          <div class="max-md:w-6/12 box-border max-md:mt-4 pr-2 mt-10 md:w-4/12 md:px-4">
            <div class="w-full bg-leone flex justify-center h-full max-md:h-36 md:h-44">
              <img src="assets/home/logo/logo-leone.png" alt=""/>
            </div>
          </div>
          <div class="max-md:w-6/12 box-border pl-2 mt-10 max-md:mt-4 md:w-4/12 md:pl-0">
            <div class="bg-savory w-full  flex justify-center h-full max-md:h-36 md:h-44">
              <img src="assets/home/logo/logo-savory.png" alt=""/>
            </div>
          </div>
        </div>
        <div class="text-xl font-normal px-10 mt-4 text-center md:text-2xl hidden md:block">Hong Kong & Shanghai</div>
      </div>
      <div class="flex flex-col items-center justify-center xl:ml-7 max-md:mt-5">
        <div class="text-xl font-normal px-10 mt-1 mb-4 md:text-2xl text-center hidden max-md:block">Tokyo & Kyoto</div>
        <div class="flex flex-wrap items-stretch border-b border-nota-red md:pb-6 max-md:border-t max-md:border-b-0">
          <div class="max-md:w-1/2 box-border pr-2 mt-10  max-md:mt-6 flex items-stretch">
            <div class="w-full bg-tokyo flex justify-center items-center h-full">
              <img src="assets/home/logo/logo-tokyo-confidential.png" alt="" class="max-md:h-36 md:h-44"/>
            </div>
          </div>
          <div class="max-md:w-1/2 box-border pl-2 mt-10  max-md:mt-6 flex items-stretch">
            <div class="w-full bg-black flex justify-center items-center h-full">
              <img src="assets/home/logo/logo-bees-knees.png" alt="" class="max-md:h-36 md:h-44"/>
            </div>
          </div>
        </div>
        <div class="text-xl font-normal px-10 mt-4 md:text-2xl text-center hidden md:block">Tokyo & Kyoto</div>
      </div>
    </div>
  </section>
  <section id="allApps" class="mt-20 flex flex-col items-center box-border py-10 max-md:mt-0">
    <div class="flex items-center">
      <img src="assets/home/logo-new.png" alt="" class="logo-new max-md:scale-75">
      <span class="text-7xl font-bold ml-6 max-md:text-3xl max-md:ml-4">Nota Q App</span>
    </div>
    <div class="flex flex-wrap items-center justify-center mt-4">
      <div
        class="rounded-2xl ins-bg w-80 h-48 box-border p-4 mt-10 md:ml-5 md:mr-5 max-md:w-11/12 max-md:h-fit max-md:flex max-md:items-center max-md:mt-6">
        <div
          class="rounded-full w-16 h-16 bg-white flex items-center justify-center text-3xl font-bold text-nota-red mr-5 max-md:w-12 max-md:h-12">
          1
        </div>
        <div class="text-2xl font-bold mt-3.5 max-md:text-lg max-md:mt-0">User-Friendly Mobile Interface</div>
      </div>
      <div
        class="rounded-2xl ins-bg w-80 h-48 box-border p-4 mt-10 md:ml-5 md:mr-5 max-md:w-11/12 max-md:h-fit  max-md:flex max-md:items-center max-md:mt-6">
        <div
          class="rounded-full w-16 h-16 bg-white flex items-center justify-center text-3xl font-bold text-nota-red mr-5 max-md:w-12 max-md:h-12">
          2
        </div>
        <div class="text-2xl font-bold mt-3.5 max-md:text-lg max-md:mt-0">Intelligent Queue Algorithms</div>
      </div>
      <div
        class="rounded-2xl ins-bg w-80 h-48 box-border p-4 mt-10 md:ml-5 md:mr-5 max-md:w-11/12 max-md:h-fit max-md:flex max-md:items-center max-md:mt-6">
        <div
          class="rounded-full w-16 h-16 bg-white flex items-center justify-center text-3xl font-bold text-nota-red mr-5 max-md:w-12 max-md:h-12">
          3
        </div>
        <div class="text-2xl font-bold mt-3.5 max-md:text-lg max-md:mt-0">Effortless Queue Management</div>
      </div>
      <div class="w-full hidden xl:block"></div>
      <div
        class="rounded-2xl ins-bg w-80 h-48 box-border p-4 mt-10 md:ml-5 md:mr-5 max-md:w-11/12 max-md:h-fit  max-md:flex max-md:items-center max-md:mt-6">
        <div
          class="rounded-full w-16 h-16 bg-white flex items-center justify-center text-3xl font-bold text-nota-red mr-5 max-md:w-12 max-md:h-12">
          4
        </div>
        <div class="text-2xl font-bold mt-3.5 max-md:text-lg max-md:mt-0">Notifications & Alerts</div>
      </div>
      <div
        class="rounded-2xl ins-bg w-80 h-48 box-border p-4 mt-10 md:ml-5 md:mr-5 max-md:w-11/12 max-md:h-fit  max-md:flex max-md:items-center max-md:mt-6">
        <div
          class="rounded-full w-16 h-16 bg-white flex items-center justify-center text-3xl font-bold text-nota-red mr-5 max-md:w-12 max-md:h-12">
          5
        </div>
        <div class="text-2xl font-bold mt-3.5 max-md:text-lg max-md:mt-0">Analytics & Insights</div>
      </div>
      <div
        class="rounded-2xl ins-bg w-80 h-48 box-border p-4 mt-10 md:ml-5 md:mr-5 max-md:w-11/12 max-md:h-fit  max-md:flex max-md:items-center max-md:mt-6">
        <div
          class="rounded-full w-16 h-16 bg-white flex items-center justify-center text-3xl font-bold text-nota-red mr-5 max-md:w-12 max-md:h-12">
          6
        </div>
        <div class="text-2xl font-bold mt-3.5 max-md:text-lg max-md:mt-0">Multi-Platform Accessibility</div>
      </div>
    </div>
  </section>
  <section class="mt-20 flex flex-col items-center box-border py-10 max-md:mt-0">
    <div class="text-5xl font-bold px-14 text-center max-md:text-3xl">Benefits of using Nota Q</div>
    <div class="w-full xl:flex">
      <div class="bg-use-left bg-no-repeat bg-cover w-full flex flex-col items-center mt-10 pb-12 bg-center xl:w-1/2">
        <div class="text-3xl font-bold text-white pt-12 max-md:text-2xl">For Hosts</div>
        <div
          class="rounded-full nota-item-bg text-black w-4/5 box-border p-4 flex items-center mt-6 max-md:w-11/12  xl:w-3/5 xl:h-44">
          <div>
            <img src="assets/home/use/icon/arrow-down.png" alt="">
          </div>
          <div class="pl-4 flex-1">
            <div class="text-2xl font-bold max-md:text-lg">Reduces Labor Cost</div>
            <div class="text-lg flex items-baseline mt-1 max-md:text-sm"><span class="px-2">•</span>Guest Self Joins
              Queue
            </div>
            <div class="text-lg flex items-baseline max-md:text-sm"><span class="px-2">•</span>Automated Guest
              Notification &
              Confirmation
            </div>
          </div>
        </div>
        <div
          class="rounded-full nota-item-bg text-black w-4/5 box-border p-4 flex items-center mt-6 max-md:w-11/12  xl:w-3/5 xl:h-44">
          <div>
            <img src="assets/home/use/icon/run.png" alt="">
          </div>
          <div class="pl-4 flex-1">
            <div class="text-2xl font-bold max-md:text-lg">Increases Revenue & Operational Efficiency</div>
            <div class="text-lg flex items-baseline mt-1 max-md:text-sm"><span class="px-2">•</span>Guest Monitors Queue
              Position &
              Eliminates Table Empty Turn Time
            </div>
          </div>
        </div>
        <div
          class="rounded-full nota-item-bg text-black w-4/5 box-border p-4 flex items-center mt-6 max-md:w-11/12  xl:w-3/5 xl:h-44">
          <div>
            <img src="assets/home/use/icon/eyeglass.png" alt="">
          </div>
          <div class="pl-4 flex-1">
            <div class="text-2xl font-bold max-md:text-lg">Enhances Visibility</div>
            <div class="text-lg flex items-baseline mt-1 max-md:text-sm"><span class="px-2">•</span>Monitor Queue Size &
              Wait Time
              Remotely
            </div>
            <div class="text-lg flex items-baseline mt-1 max-md:text-sm"><span class="px-2">•</span>AI Algorithms
              Analyze Traffic Flow
              &
              Wait Times
            </div>
          </div>
        </div>
      </div>
      <div
        class="bg-use-right bg-no-repeat bg-cover w-full flex flex-col items-center mt-10 pb-12 bg-center max-xl:mt-0 xl:w-1/2">
        <div class="text-3xl font-bold text-white pt-12 max-md:text-2xl">For guests</div>
        <div
          class="rounded-full nota-item-bg text-black w-4/5 box-border p-4 flex items-center mt-6 max-md:w-11/12 xl:w-3/5 xl:h-44">
          <div>
            <img src="assets/home/use/icon/eye.png" alt="">
          </div>
          <div class="pl-4 flex-1">
            <div class="text-2xl font-bold max-md:text-lg">Increases Transparency</div>
            <div class="text-lg flex items-baseline mt-1 max-md:text-sm"><span class="px-2">•</span>Guest Self Monitors
              Queue Position
            </div>
          </div>
        </div>
        <div
          class="rounded-full nota-item-bg text-black w-4/5 box-border p-4 flex items-center mt-6 max-md:w-11/12 xl:w-3/5 xl:h-44">
          <div>
            <img src="assets/home/use/icon/users.png" alt="">
          </div>
          <div class="pl-4 flex-1">
            <div class="text-2xl font-bold max-md:text-lg">Improves Guest Experience</div>
            <div class="text-lg flex items-baseline mt-1 max-md:text-sm"><span class="px-2">•</span>No Time Wasted
              Standing in a Queue
            </div>
          </div>
        </div>
        <div
          class="rounded-full nota-item-bg text-black w-4/5 box-border p-4 flex items-center mt-6 max-md:w-11/12 xl:w-3/5 xl:h-44">
          <div>
            <img src="assets/home/use/icon/arrow-down.png" alt="">
          </div>
          <div class="pl-4 flex-1">
            <div class="text-2xl font-bold max-md:text-lg">Reduces No-Show or Cancellation</div>
            <div class="text-lg flex items-baseline mt-1 max-md:text-sm"><span class="px-2">•</span>Guest Understands
              Seating
              Possibility Despite Long Wait
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="mt-20 flex flex-col items-center box-border py-10 max-md:mt-0">
    <div class="text-5xl font-bold px-14 text-center box-border max-md:text-3xl max-md:px-8 xl:px-96 2xl:px-14">
      User-friendly Interface
      Optimised for
      Guests & Hosts
    </div>
    <div class="relative flex flex-col items-center">
      <div class="flex w-full box-border md:pl-4 md:pr-4 max-md:flex-col xl:pl-48 xl:pr-48 xl:pb-20">
        <div
          class="w-1/2 box-border flex flex-col items-center mt-14 max-md:w-full max-md:pr-4 max-md:items-start xl:flex-row-reverse xl:items-start">
          <div class="w-full flex justify-center xl:w-fit xl:px-2.5"><img src="assets/home/nota-q-iphone-1.png"
                                                                          class="nota-iphone" alt=""></div>
          <div class="box-border pl-4 mt-10 max-md:mt-6 xl:w-64 xl:mr-10 xl:mt-0">
            <div class="text-2xl font-bold ">Host Interface</div>
            <div class="text-xl pl-4 border-nota-red border-l mt-4 max-md:text-lg max-md:mt-2">Quick Summary of Guests
              in
              Queue
            </div>
            <div class="text-xl pl-4 border-nota-red border-l mt-4 max-md:text-lg max-md:mt-2">Easy Organization by
              Queue
              Status
            </div>
            <div class="text-xl pl-4 border-nota-red border-l mt-4 max-md:text-lg max-md:mt-2">Guest Names in Queue
              Hidden
              for Privacy
            </div>
          </div>
        </div>
        <div
          class="w-1/2 box-border flex flex-col items-center mt-14 max-md:w-full max-md:pr-4 max-md:items-start xl:flex-row xl:pt-28 xl:items-start">
          <div class="w-full flex justify-center xl:w-fit xl:px-2.5"><img src="assets/home/nota-q-iphone-2.png" alt=""
                                                                          class="nota-iphone"></div>
          <div class="box-border pl-4 mt-10 max-md:mt-6 xl:w-64 xl:ml-10 xl:mt-0">
            <div class="text-2xl font-bold">Guest Interface</div>
            <div class="text-xl pl-4 border-nota-red border-l mt-4 max-md:text-lg max-md:mt-2">Customized with the
              Venue’s
              Logo and Color
            </div>
            <div class="text-xl pl-4 border-nota-red border-l mt-4 max-md:text-lg max-md:mt-2">Follow Your Queue
              Position
              Without Asking the Host
            </div>
            <div class="text-xl pl-4 border-nota-red border-l mt-4 max-md:text-lg max-md:mt-2">Exit the Queue Anytime
            </div>
          </div>
        </div>
      </div>
      <img src="assets/home/bg-phone-shadow.png" alt="" class="absolute -bottom-24 hidden xl:block"/>
    </div>

  </section>
  <section class="mt-20 flex flex-col items-center box-border py-10 max-md:mt-0">
    <div class="px-20 max-md:px-4">
      <div class="text-5xl font-bold px-14 text-center max-md:text-3xl max-md:px-0">Adaptable Workflow</div>
      <div class="text-5xl px-14 text-center mt-2 max-md:text-xl">Many ways to join queue</div>
    </div>
    <div class="mt-12 px-14 max-md:px-4">
      <img src="assets/home/user/workflow.png" class="w-full" alt="">
    </div>
  </section>
  <section class="mt-20 flex flex-col items-center box-border py-10 max-md:mt-0">
    <div class="text-5xl font-bold px-14 text-center max-md:text-3xl max-md:px-6 xl:px-96 2xl:px-14">
      Multi-platform support to notify guests
    </div>
    <div
      class="px-14 flex items-center flex-wrap justify-between box-border w-full md:pl-20 md:pr-20 max-md:px-4 xl:justify-center">
      <div class="mt-12 max-md:mt-6 box-border xl:w-72 xl:mr-10">
        <div class="text-2xl pl-4 border-nota-red border-l mt-4 flex items-center max-md:text-xl">
          <img src="assets/home/checkbox-round.png" class="w-8 max-md:w-6" alt="">
          <span class="ml-4">SMS</span>
        </div>
        <div class="text-2xl pl-4 border-nota-red border-l mt-4 flex items-center max-md:text-xl">
          <img src="assets/home/checkbox-round.png" class="w-8 max-md:w-6" alt="">
          <span class="ml-4">Nota Q Guest App Push Notification</span>
        </div>
        <div class="text-2xl pl-4 border-nota-red border-l mt-4 flex items-center max-md:text-xl">
          <img src="assets/home/checkbox-round.png" class="w-8 max-md:w-6" alt="">
          <span class="ml-4">WhatsApp Cloud</span>
        </div>
        <div class="text-2xl pl-4 border-nota-red border-l mt-4 flex items-center max-md:text-xl">
          <img src="assets/home/checkbox-round.png" class="w-8 max-md:w-6" alt="">
          <span class="ml-4">Nota Q WeChat Mini Program</span>
        </div>
      </div>
      <div class="w-full xl:w-fit flex justify-end">
        <img src="assets/home/notification-examples.png" style="width: 56rem" class="mt-12" alt="">
      </div>
    </div>
  </section>
  <section id="contactus"
           class="w-full pt-12 pb-24 bg-nota-red text-white md:pl-12 md:pr-12 max-md:pl-6 max-md:pr-6 box-border xl:pl-48 xl:pr-48">
    <div class="max-w-screen-2xl mx-auto">
      <div class="text-white w-full">
        <div class="w-full">
          <div class="text-5xl max-md:text-3xl">Get In Touch</div>
          <div class="flex flex-wrap items-center">
            <div class="w-full box-border mt-6 xl:w-1/2 xl:pr-2">
              <div class="text-lg max-md:text-base">Name</div>
              <input matInput [(ngModel)]="name"
                     class="h-12 bg-transparent w-full border-b mt-6 border-nota-yellow rounded-none caret-nota-yellow focus:outline-none max-md:h-8"
                     placeholder="Your Name">
            </div>
            <div class="w-full box-border mt-6 xl:w-1/2  xl:pl-2">
              <div class="text-lg max-md:text-base">Email</div>
              <input matInput [(ngModel)]="email"
                     class="h-12 bg-transparent w-full border-b mt-6 border-nota-yellow rounded-none caret-nota-yellow focus:outline-none max-md:h-8"
                     placeholder="Your email">
            </div>
          </div>
          <div class="mt-6">
            <div class="w-full">
              <div class="text-lg max-md:text-base">Message</div>
              <textarea matInput [(ngModel)]="message" placeholder="Let us know how could we help here"
                        class="min-h-12 bg-transparent w-full border-b mt-6 border-nota-yellow rounded-none caret-nota-yellow focus:outline-none max-md:min-h-6"></textarea>
            </div>
          </div>

          <div class="mt-8 w-full flex justify-end">
            <button
              class="w-full px-10 rounded-full bg-black text-white flex items-center justify-center min-h-14 text-xl max-md:min-h-12 max-md:text-lg xl:w-fit"
              (click)="submit()">Submit
              <img src="assets/home/right-arrow.png" class="btn-icon"></button>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
