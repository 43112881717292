import {Venue} from "./Venue";
import {Guest} from "./Guest";

export interface WaitListEntryRes {
  status: string;
  waitlistEntry: WaitListEntry;
}

export const SUCCESS: string = 'success';
export interface CommonRes {
  status: string;
  message?: string;
}

export interface WaitListEntry {
  _id: string;
  uniqueCode: number;
  venue: Venue;
  guest: Guest;
  guestCount: number;
  status: string;
  seatingPreference: string;
  notes: string;
  queuedAt: string;
  queuePosition: number;
  quotedWaitMin: number;
  estimatedArrival: string;
  expirationDate?: string;
}
