<div *ngIf="session.waitList" class="page">
  <div class="page-content">
    <div class="logo" [style.background-color]="session.waitList.venue.headerBackgroundColor">
      <img [src]="session.waitList.venue.logoNav">
    </div>
    <div class="content">
      <div *ngIf="session.waitList.status === 'CLAIMED'">
        <app-signup [venue]="session.waitList.venue"></app-signup>
      </div>

      <div *ngIf="session.waitList.status !== 'CLAIMED'">
        <app-qposition [waitList]="session.waitList"></app-qposition>
      </div>
    </div>

    <div class="footer">
      <div class="row align-item-center">
        <div class="col">
          <img src="assets/icons/web.png" class="icon cursor-pointer" (click)="lang.openLanguage()">
          <span class="icon-text cursor-pointer" (click)="lang.openLanguage()">{{lang.langDisplay}}</span>
        </div>
        <div class="col text-right">
          <img src="assets/img/logo-notaq.png" class="footer-logo">
        </div>
      </div>
    </div>

  </div>
</div>
